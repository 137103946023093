import React from "react";
import { Box, Typography, Card, CardContent, Grid, Avatar } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Computer, Favorite, Agriculture, Poll } from "@mui/icons-material";

export const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  borderRadius: "20px",
  padding: "0px 16px",
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 10px 20px rgba(0,0,0,0.1)",
  },
  background: "linear-gradient(145deg, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%)",
}));

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(10),
  height: theme.spacing(10),
  marginBottom: theme.spacing(2),
  background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
  boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
}));

export const achievements = [
  {
    title: "バーチャルオフィスの開発",
    description:
      "リモートワーク時代のニーズに応え、バーチャルな空間でのオフィス体験を提供。チームの連携を強化し、生産性を向上させます。",
    icon: <Computer fontSize="large" />,
  },
  {
    title: "ikigai支援アプリの開発",
    description:
      "人々が自分の生きがいを見つけ、充実した生活を送るためのサポートアプリ。パーソナライズされた提案で、毎日の生活に新たな価値を見出します。",
    icon: <Favorite fontSize="large" />,
  },
  {
    title: "農家支援予約アプリの開発",
    description:
      "地域の特産品である梅の予約システムを構築。生産者と消費者を繋ぎ、地域活性化に貢献します。",
    icon: <Agriculture fontSize="large" />,
  },
  {
    title: "アンケートアプリの開発",
    description:
      "効果的な意見収集を可能にするアンケートツール。企業や団体のニーズに応じたカスタマイズが可能で、意思決定をサポートします。",
    icon: <Poll fontSize="large" />,
  },
];
