import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import { Helmet } from "react-helmet";

function App() {
  return (
    <Router>
      <Helmet>
        <title>株式会社Micompass</title>
        <link rel="icon" href="/images/logo_white_cir.png" />

        {/* 基本的なメタタグ */}
        <meta
          name="description"
          content="最先端の技術を活用し、お客様のビジネスの成長をサポートします。"
        />

        {/* Open Graph (Facebook, LinkedIn) */}
        <meta property="og:title" content="Micompass Website" />
        <meta
          property="og:description"
          content="最先端の技術を活用し、お客様のビジネスの成長をサポートします。"
        />
        <meta property="og:image" content="https://micompass.co.jp/images/OGPmojiari.png" />
        <meta property="og:url" content="https://micompass.co.jp" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="ja_JP" />
        <meta property="og:site_name" content="株式会社Micompass" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@micompass" />
        <meta name="twitter:title" content="Micompass Website" />
        <meta
          name="twitter:description"
          content="Micompassは、最先端の技術を活用して、お客様のビジネスの成長をサポートします。"
        />
        <meta name="twitter:image" content="https://micompass.co.jp/images/OGPmojiari.png" />

        {/* モバイル対応 */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
