import React from "react";
import { Box, Typography, Button, Grid, CardContent, useTheme, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import Header from "../../components/Header";
import { AboutUs1, AboutUs2 } from "../../components/Section/AboutUs";
import { achievements, StyledCard, StyledAvatar } from "../../components/Section/AchievementsUtils";
import Footer from "../../components/Footer";

const OuterBox = styled(Box)(({ theme }) => ({
  backgroundAttachment: "scroll",
  background: "url('/images/tentai_haikei3.png') bottom no-repeat",
  backgroundSize: "100% auto",
  backgroundRepeat: "no-repeat",
  margin: "-8px",
}));

const TitleSection = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
  width: "100%",
  background: "url('/images/tentai_haikei1.png') center center no-repeat",
  backgroundSize: "cover",
  position: "relative",
  marginBottom: "40px",
  padding: theme.spacing(2),
  textAlign: "center",
}));

const AchievementsSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(0, 7.5),
  },
  marginTop: theme.spacing(4),
}));

const ResponsiveTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
  },
}));

const Home: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const headerOffset = 64;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <OuterBox>
      <Header scrollToSection={scrollToSection} />
      <TitleSection id="home">
        <ResponsiveTypography variant="h2" fontWeight={700} gutterBottom color="white">
          Your Dream Guide
        </ResponsiveTypography>
        <Typography variant="h4" fontWeight={500} gutterBottom color="white">
          ポケットに小さな道標を.
        </Typography>
      </TitleSection>
      <Grid
        container
        spacing={4}
        id="about"
        sx={{
          padding: (theme) => theme.spacing(2),
          [theme.breakpoints.up("md")]: {
            padding: (theme) => theme.spacing(0, 7.5),
          },
          marginBottom: "40px",
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h4" fontWeight="bold" gutterBottom>
            会社概要
          </Typography>
          <Typography variant="body1" color="text.primary" gutterBottom>
            Micompassは、最先端の技術を活用して、お客様のビジネスの成長をサポートする会社です。
            <br />
            私たちは、革新的なソリューションと高品質なサービスを提供することで、デジタル世界での活躍をサポートします。
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <AboutUs1 />
        </Grid>
        <Grid item xs={12} md={6}>
          <AboutUs2 />
        </Grid>
      </Grid>

      <AchievementsSection>
        <Grid container spacing={4} id="achievements">
          <Grid item xs={12}>
            <Typography variant="h4" fontWeight="bold" gutterBottom color="white">
              実績
            </Typography>
          </Grid>
          {achievements.map((achievement, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <StyledCard elevation={3}>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <StyledAvatar>{achievement.icon}</StyledAvatar>
                  <Typography variant="h5" gutterBottom fontWeight="bold" color="primary">
                    {achievement.title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary" textAlign="left">
                    {achievement.description}
                  </Typography>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>

        <Box
          mt={4}
          style={{ width: "100%", display: "flex", justifyContent: "center", marginBottom: 2 }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: "#d83664",
              color: "#fff",
              borderRadius: 24,
              padding: "10px 20px",
              textTransform: "none",
              minWidth: 200,
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            お問い合わせ
          </Button>
        </Box>
      </AchievementsSection>

      <Footer />
    </OuterBox>
  );
};

export default Home;
