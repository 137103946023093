import React from "react";
import { Box, Container, Typography } from "@mui/material";

const Footer = () => (
  <Box component="footer" sx={{ bgcolor: "#121268", py: 3, margin: "-8px" }}>
    <Container maxWidth="lg">
      <Typography variant="body2" color="white" align="center">
        © {new Date().getFullYear()} Micompass. All rights reserved.
      </Typography>
    </Container>
  </Box>
);

export default Footer;
