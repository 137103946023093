import React from "react";
import {
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: "20px",
  boxShadow: "0 8px 32px rgba(31, 38, 135, 0.37)",
  backdropFilter: "blur(4px)",
  border: "1px solid rgba(255, 255, 255, 0.18)",
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 12px 40px rgba(31, 38, 135, 0.5)",
  },
}));

const CuteTypography = styled(Typography)(({ theme }) => ({
  position: "relative",
  display: "inline-block",
  "&::after": {
    content: "''",
    position: "absolute",
    bottom: "-5px",
    left: 0,
    width: "100%",
    height: "3px",
    background: "linear-gradient(90deg, #ff9a9e 0%, #fad0c4 100%)",
    borderRadius: "10px",
  },
}));

const IconWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginBottom: "10px",
  "& > svg": {
    marginRight: "10px",
  },
});

export const AboutUs1 = () => {
  return (
    <CustomPaper elevation={2}>
      <IconWrapper>
        <CuteTypography variant="h6" color="primary" fontWeight="bold">
          基本情報
        </CuteTypography>
      </IconWrapper>
      <TableContainer>
        <Table>
          <TableBody>
            {[
              { label: "社名", value: "株式会社Micompass" },
              { label: "設立", value: "2023年10月" },
              { label: "資本金", value: "1,000万円" },
              { label: "代表取締役", value: "島田哲朗" },
              { label: "所在地", value: "〒142-0041 東京都品川区戸越1丁目20-8 1201" },
            ].map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography variant="body2" fontWeight="bold">
                    {row.label}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{row.value}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CustomPaper>
  );
};

export const AboutUs2 = () => {
  return (
    <CustomPaper elevation={3}>
      <IconWrapper>
        <CuteTypography variant="h6" color="primary" fontWeight="bold">
          事業内容
        </CuteTypography>
      </IconWrapper>
      <TableContainer>
        <Table>
          <TableBody>
            {[
              "アプリケーション及びウェブサービスの企画開発、販売、運営、コンサルティング",
              "データ分析及びそれらに関するコンサルティング",
              "企業の従業員または個人に対する健康上の課題解決のための支援事業",
              "有料職業紹介事業",
            ].map((content, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography variant="body2">{content}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CustomPaper>
  );
};
